<template>
<div class="d-flex justify-content-center">
  <div class="guest mt-2 mb-4 p-2">
  <!-- Has an account but waiver not signed -->
  <div v-if="account && $parent.user && !$parent.user.waiverSigned">
    <tou v-on:signToU="signToU"/>
  </div>
  <!-- Has an account -->
  <div v-else-if="account && $parent.user">
    <h1>Account</h1>
    <p>
      Note: This information is private. It is only used to authenticate you and is not shared with other users without your explicit permission.
    </p>
    <div>
      <div v-if="account.isAnonymous">Anonymous</div>
      <div v-if="account.displayName">Name: {{ account.displayName }}</div>
      <div v-if="account.email">
        Email: {{ account.email }}
        <button v-if="!account.emailVerified" class="btn btn-primary" @click="verifyEmail()">Verify Email</button>
        <span v-else class="badge badge-secondary">Verified</span>
      </div>
      <div v-if="account.phoneNumber">Phone Number: {{ account.phoneNumber }}</div>
      <div v-if="accountToken && accountToken.claims">
        <span v-if="accountToken.claims.admin" class="badge badge-danger">Admin</span>
      </div>
    </div>
    <div class="mt-4">
      <!-- <button class="m-1 btn btn-primary" @click="editProfile()">Edit Profile</button> -->
      <button class="btn btn-primary" @click="logout()">Logout</button>
    </div>
  </div>
  <!-- Account but no user -->
  <div v-else-if="account && !edit">
    <b-spinner small variant="primary" label="Spinning"></b-spinner>
    Authenticating...
  </div>
  <div v-else-if="account && edit">
    <div v-if="event && event.signUpMessages">
      <div class="text mt-3" v-for="(m, index) in event.signUpMessages" :key="index">
        {{ inject(m, $app) }}
      </div>
    </div>
    <profile-form
      v-if="!eventId || event"
      :event="event"
      v-on:complete="profileComplete"/>
  </div>
  <!-- No account yet -->
  <div v-else>
    <b-alert variant="info" :show="$parent.manager.promotedEventId">
      An event is running right now, you might have time to join.
    </b-alert>
    <div class="d-flex flex-column text-center">
      <div class="m-4">
        <a :href="$app.productURL"><img :src="$app.productLogoSmall" width="180"/></a>
      </div>
      <p>{{ $app.tagLine }}</p>
      <div id="firebaseui-auth-container"></div>
    </div>
  </div>
  </div>
</div>
</template>

<script>
import { firebase } from "@/services/firebase";
import { db } from "@/services/db";
import "firebase/auth";
let auth = firebase.auth();

import { inject } from "@/services/utils"
import { upgradeAuth } from "@/services/functions";
import * as firebaseui from 'firebaseui';
import "firebaseui/dist/firebaseui.css";
import { getLog } from "@/services/log";
let log = getLog("guest-signup");
import profileForm from "@/pages/profileForm";
import tou from "@/pages/tou";

export default {
  name: 'app',
  props: {
    eventId: String,
  },
  components: {
    profileForm,
    tou
  },
  data() {
    return {
      event: null,
      account: null,
      accountToken: null,

      edit: false,
    };
  },
  watch: {
    user() {
      this.$parent.hideNav = !this.$parent.user;
      this.$parent.hideTitle = !this.$parent.user;
    }
  },
  async mounted() {
    log.log("signUp mounted", this.eventId);
    if (this.eventId)
      this.$bind("event", db.collection("LiveEvents").doc(this.eventId));
    this.$parent.hideNav = !this.$parent.user;
    this.$parent.hideTitle = !this.$parent.user;
    if (this.$app.skipAccount) {
      this.account = { isAnonymous:true };
      this.edit = true;
      this.$parent.hideTitle = false;
    } else {
      this.setupUI();
      this.AuthStateChangedListener = auth.onAuthStateChanged(account => {
        log.log("onAuthStateChanged", account);
        this.account = account;
      });
      this.IdTokenChangedListener = auth.onIdTokenChanged(async account => {
        this.accountToken = account ? await account.getIdTokenResult() : null;
        log.log("onIdTokenChanged", this.accountToken);
        setTimeout(() => {
          if (!this.$parent.user) {
            log.log("no user loaded, requesting upgradeAuth");
            this.upgradeAuth();
          }
        }, 2000);
      });
    }
    // forced logout
    if (this.$route.query.logout) {
      this.logout();
      this.$router.replace({ query: {} });
    }
  },
  beforeDestroy() {
    if (this.AuthStateChangedListener) this.AuthStateChangedListener();
    if (this.IdTokenChangedListener) this.IdTokenChangedListener();
  },
  methods: {
    inject,
    // Authentication and claims
    setupUI() {
      let signInOptions = [
        // Leave the lines as is for the providers you want to offer your users.
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ]
      if (!this.$app.signInOptionsNoGoogle)
        signInOptions.push(firebase.auth.GoogleAuthProvider.PROVIDER_ID);
      log.log("signInOptions=", signInOptions);

      // FirebaseUI config.
      let uiConfig = {
        signInSuccessUrl: window.location.href,
        callbacks: {
          signInSuccessWithAuthResult: () => {
            this.upgradeAuth();
            // Do not automatically redirect.
            return false;
          },
        },
        signInOptions,
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '/terms-of-use',
        // Privacy policy url/callback.
        privacyPolicyUrl: () => {
          window.location.assign('/privacy-policy');
        }
      };

      //log.log("Firebaseui:", firebaseui);
      // Initialize the FirebaseUI Widget using Firebase.
      let ui = firebaseui.auth.AuthUI.getInstance();
      if (!ui) {
          ui = new firebaseui.auth.AuthUI(firebase.auth());
      }
      // The start method will wait until the DOM is loaded.
      ui.start('#firebaseui-auth-container', uiConfig);
    },
    async logout() {
      localStorage.removeItem('userid');
      log.log("logout")
      await auth.signOut();
      setTimeout(() => {
        this.setupUI();
      }, 100);
    },
    async verifyEmail() {
      log.log("verifyEmail")
      await auth.currentUser.sendEmailVerification();
      log.log("email Sent");
    },
    async upgradeAuth() {
      log.log("upgradeAuth");
      if (!auth.currentUser) {
        log.log("no user to upgrade");
        return;
      }
      try {
        let r = await upgradeAuth();
        log.log("upgradeAuth response: ", JSON.stringify(r));
        if (r.data) {
          if (r.data.user) {
            await this.loadProfile(r.data.user.id);
            this.processRedirect();
          }
          if (r.data.need_refresh)
            auth.currentUser.getIdToken(true);
        }
      } catch (err) {
        log.log("upgradeAuth error", err);
      } finally {
        this.edit = true;
      }
    },
    async profileComplete(user) {
      if (this.account.email) {
        user.email = this.account.email;
        user.emailVerified = false;
      }
      if (this.account.phoneNumber) {
        user.phoneNumber = this.account.phoneNumber;
        user.phoneNumberVerified = false;
      }
      if (this.$app.skipToU)
        user.waiverSigned = true;
      let u = await db.collection("LiveUsers").add(user);
      log.log("profile created", u.id);
      await this.loadProfile(u.id);
      if (this.$app.skipToU)
        this.processRedirect('/guest/events/list')
    },
    async loadProfile(uid) {
      return await new Promise((resolve) => {
        // $emit is not awaitable https://stackoverflow.com/a/55364350/318839
        this.$emit("load-user", uid, user => resolve(user));
      });
    },
    editProfile() {
      this.edit = true;
    },
    signToU(sign) {
      db.collection("LiveUsers").doc(this.$parent.user.id).update({waiverSigned:sign});
      if (sign)
        this.processRedirect('/guest/events/list')
    },
    processRedirect(defaultRoute) {
      let redirectRoute = this.$route.query.redirect || defaultRoute;
      log.log(`processRedirect ${redirectRoute}`);
      if (redirectRoute)
        this.$router.push(redirectRoute);
    }
  }
}
</script>

<style scoped>

</style>